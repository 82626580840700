import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SingleSelect from "../../general/core/SingleSelect";
import SingleInput from "../../general/core/SingleInput";
import DatePicker from "react-date-picker";
import { getPaymentMethods } from "../../../actions/paymentLinkAction";
import moment from "moment";

const PaymentDetailsForm = ({ updateForm, getPaymentMethods, paymentMethods, serverFilterData, usePrevFormData, prevFormData, amountInputWrong, showBookingDate }) => {
  const [dateVal, setDateVal] = useState(null);

  const ERROR_TEXT_AMOUNT = "Please enter amount in format: 0.00";

  const handleSelectMerchant = event => {
    updateForm(event.name, event.value);
    requestPaymentMethodsByMerchantID(event.value);
  };

  const requestPaymentMethodsByMerchantID = async (id) => {
    await getPaymentMethods({ merchantid: id });
  };

  const handleInputChange = event => {
    if (event.target) {
      updateForm(event.target.name, event.target.value);
    } else {
      updateForm(event.name, event.value);
    }
  };

  const handleDatePicker = date => {
    setDateVal(date);
    updateForm("bookingDate", moment(date).format("YYYY-MM-DD"))
  }

  return (
    <div className="paymentDetailsForm">
      <div
        style={{
          width: "100%",
          borderBottom: "1px solid #E2E8F0",
          fontSize: "20px",
          fontWeight: "bold",
          color: "#475569",
          marginBottom: showBookingDate ? "20px" : "32px",
        }}
      >
        Payment details
      </div>

      <SingleSelect
        id="merchantIDSelection"
        placeholder="Select account"
        width="100%"
        label="Merchant account"
        name="merchant"
        options={
          serverFilterData["merchant_name"] !== undefined
            ? serverFilterData["merchant_name"]
            : []
        }
        marginTop={showBookingDate ? "15px" : "32px"}
        onChange={handleSelectMerchant}
        defaultValue={usePrevFormData ? {label: prevFormData["merchant"], value: prevFormData["merchant"]}   : ""}
      />

      <SingleInput
        id="merchantIDSelection"
        width="100%"
        label="Order ID"
        name="order"
        autoComplete="off"
        pattern="^[a-zA-Z0-9 -/_/]{0,40}$"
        title="Characters must be alphanumeric"
        marginTop="4px"
        onChange={handleInputChange}
        isOptional={true}
        defaultValue={usePrevFormData ? prevFormData["order"] : ""}
      />

      {showBookingDate && (
        <div>
          <div style={{ background: "#F8FAFC", textAlign: "left" }}>
            <label style={{ color: "#475569" }}>Booking date</label>
            <label
              style={{
                color: "hsl(0,0%,50%)",
                fontWeight: "normal",
                float: "right",
              }}
            >
              optional
            </label>
          </div>
          <DatePicker
            style={{ width: "100%" }}
            id="bookingDate"
            onChange={handleDatePicker}
            value={dateVal}
            className="pl-date-picker"
            calendarClassName="pl-date-calendar"
            clearIcon={null}
            dayPlaceholder="DD"
            monthPlaceholder="MM"
            yearPlaceholder="YYYY"
          />
          <div style={{ height: "20px" }}></div>
        </div>
      )}

      <div className="row" style={{ margin: "4px 0px 0px 0px" }}>
        <div className="col-md-3" style={{ padding: "0px" }}>
          <SingleSelect
            id="selectCurrency"
            label="Currency"
            name="currency"
            placeholder=""
            marginRight="0px"
            marginLeft="0px"
            marginTop="4px"
            options={usePrevFormData ? prevFormData["paymentCurrencies"] : paymentMethods?.paymentMethodsData?.data?.currencies?.split(",") ?? []}
            onChange={handleInputChange}
            menuHeight={(usePrevFormData && prevFormData["paymentCurrencies"].length > 3) || paymentMethods?.paymentMethodsData?.data?.currencies?.split(",").length > 3 ? "120px" : ""}
            defaultValue={usePrevFormData ? {label: prevFormData["currency"], value: prevFormData["currency"]} : ""}
          />
        </div>

        <div className="col-md-9" style={{padding:"0px 0px 0px 7px"}}>
          <SingleInput
            label="Amount"
            name="amount"
            autoComplete="off"
            placeholder="0.00"
            pattern="^\d{1,8}\.\d{0,2}$|^\d{1,8}$"
            title="The number input must start with a number and use a dot as a decimal character."
            marginTop="4px"
            onChange={handleInputChange}
            errorMsg={ERROR_TEXT_AMOUNT}
            showErrorMsg={amountInputWrong}
            defaultValue={usePrevFormData ? prevFormData["amount"] : ""}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  paymentMethods: state.paymentLinkReducer,
});

const mapDispatchToProps = {
  getPaymentMethods
};
  
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentDetailsForm)
);